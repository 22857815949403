/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'src/utils/hooks/storage'
import { STORAGE_KEYS } from 'src/utils/storage-keys'
import { LOGIN_URL } from '../urls'
import { useAxios } from './axios-context'

const authContext = createContext()

export const useAuth = () => useContext(authContext)

export function AuthProvider({ children }) {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

function useProvideAuth() {
  const [user, setUser] = useLocalStorage(STORAGE_KEYS.user, null)
  const axios = useAxios()

  const signin = async (data) => {
    const response = await axios.post(`${LOGIN_URL}`, data)
    const { data: userObj } = response
    return userObj
  }

  const signout = () => {
    console.log('Resetting')
    setUser(null)

    console.log('Reset Complete')
  }

  return {
    user: user,
    signin: signin,
    signout: signout,
  }
}
