export const DEV_BASE_URL = 'http://localhost'
export const STAGING_BASE_URL = 'https://kt.laheri.co.ke'
export const PRODUCTION_BASE_URL = 'https://portal.kwanzatukule.com'

export const STAGING_BASE_API_URL = 'https://api.kt.laheri.co.ke/api/v1/'
export const PRODUCTION_BASE_API_URL = 'https://api.portal.kwanzatukule.com/api/v1/'
export const DEV_BASE_API_URL = 'http://209.97.134.198:8080/api/v1'

export let BASE_URL = ''
export let BASE_API_URL = ''
export const location = window && window.location

export const hostname = `${location.protocol}//${location.hostname}`

switch (hostname) {
  case STAGING_BASE_URL:
    BASE_URL = STAGING_BASE_URL
    BASE_API_URL = STAGING_BASE_API_URL
    break

  case PRODUCTION_BASE_URL:
    BASE_URL = PRODUCTION_BASE_URL
    BASE_API_URL = PRODUCTION_BASE_API_URL
    break

  default:
    BASE_URL = `${DEV_BASE_URL}:${location.port}`
    BASE_API_URL = `${PRODUCTION_BASE_API_URL}`
    break
}
